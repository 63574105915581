module.exports = {
  siteTitle: 'Guillermo Loza', // <title>
  manifestName: 'Grayscale',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/portfolio/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Frontend Developer',
  subHeading: 'Full time Web Developer. Always in self development ',

  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/GuillermoLoza',
    },
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/guillermo-loza-hernandez-025164194/',
    },
  ],
  email: 'me@guillermoloza.com',
  phone: '000-00000',
  address: 'State of Mexico, México',
};
